import React from 'react'
import illustrationPic from '@/../../src/images/Illustration.png'

const Ultimate = () => {
  // return (
  //   <div className='text-white pb-6'>
  //     <div>
  //       <h1 className='flex justify-center text-[56px] leading-[50px]  mx-2'>Building The Ultimate</h1>
  //       <h1 className='flex justify-center text-[56px] leading-[50px] mb-4 mx-2'>Backtesting Platform.</h1>
  //       <p className='flex justify-center text-[18px] leading-[20px] text-[#E5E6ED] mx-2'>We are a team of passionate and creative professionals dedicated to making</p>
  //       <p className='flex justify-center text-[18px] leading-[20px] text-[#E5E6ED] mx-2'>backtesting an enjoyable and empowering experience for traders.</p>
  //       <div className='flex justify-center my-10 mx-3'> 
  //           <img src={illustrationPic} alt="illustrationPic" />
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default Ultimate
