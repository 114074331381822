import React from "react";
import cardten from "../../src/images/DTA.png";
import { Link } from "react-router-dom";

const Trusted = () => {
  return (
    <>
      <div className=" text-white flex flex-col items-center justify-center py-16">
        <div className="w-full flex justify-center flex-col items-center">
          <h1 className="text-7xl font-semibold  flex justify-center mx-2">
            Welcome To The
          </h1>
          <h1 className="text-7xl font-semibold  flex justify-center mx-2">
            World's First Competitive
          </h1>
          <h1 className="text-7xl font-semibold  flex justify-center mx-2">
            Trading Software
          </h1>
        </div>
        <div className="w-full flex justify-center my-10 flex-col ">
          <p className="flex justify-center place-self-center text-[#E5E6ED] text-[18px] mx-2">
            Want to become a champion of the charts? Join today to compete with
            other traders
          </p>
          <p className="flex justify-center place-self-center text-[#E5E6ED] text-[18px] mx-2">
            and test your knowledge against the world!
          </p>
        </div>
        <Link
          to={"https://app.chartchamps.com/"}
          className="bg-[#5235E8] py-2 px-5 rounded-full mr-2 hover:shadow-xl transition-colors duration-300 ease-in-out hover:bg-blue-900"
        >
          Play Now
        </Link>
      </div>

      <div className="w-full flex justify-center">
        <div className="flex justify-center w-8/12 flex-wrap">
          <img src={cardten} alt="card-ten" />
        </div>
      </div>
    </>
  );
};

export default Trusted;
