import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "#2F324190",
  color: "#fff",
  boxShadow: "none",
  borderRadius: "16px",
  transition: "transform 0.3s ease",
  "&:before": {
    display: "none",
  },
  border: "1px solid #333",
  "&:hover": {
    backgroundColor: "#2F324200",
    transform: "scale(1.02)",
  },
  marginBottom: "16px",
  width: "100%",
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  "& .MuiAccordionSummary-content": {
    margin: "0",
  },
  minHeight: "80px",
}));

const Started = () => {

  const faqs = [
    {
      question: "How to Play",
      answer: `1. Sign In and Create an Account: Begin by signing in and setting up your account.
                2. Access Live Competitions: Once you’re logged in, navigate to the live competition page.
                3. Join a Tournament: Click the purple play button to enter an available tournament. You’ll be redirected to a payment page.
                4. Complete Your Payment: After successfully completing your payment, return to the live competition page. The tournament you just paid for will now be accessible.
                5. Trade the Data: Enter the tournament and trade the provided data set to the best of your ability. When the trading period ends, you’ll receive a notification that the tournament is over.`,
    },
    {
      question: "Why Can't I Enter a Competition?",
      answer: `If you're unable to enter a tournament, it could be due to several reasons:
               • Entry Fee Not Paid: Ensure you’ve paid the entry fee.
               • Tournament Not Yet Available: Check the scheduled time; for example, tournaments may run from 12 PM to 6 PM EST.
               • Same-Day Tournament Limitations: Remember that if the tournament occurs today (Saturday/Sunday), it may already be underway.
               • Tournament Has Ended: Ensure that the tournament you want to enter is still open.
               • Player Spots Are Full: The tournament may have reached its maximum participant capacity.
               • If you have further questions, feel free to reach out on the form below!`,
    },
    {
      question: "When Are Tournament Results Released?",
      answer: `Tournament results will be available shortly after the competition concludes. For example, if the tournament runs from 12 PM to 6 PM EST, results will be posted shortly after 6 PM EST.`,
    },
    {
      question: "How to Claim Your Winnings",
      answer: `If you finish in a prize-eligible position, congratulations! To claim your winnings, head to the Tournament Winnings page located at the top of the screen and fill out the tournament winnings form. Once your submission is approved, you can expect to receive your winnings within a few days.`,
    },
  ];

  return (
    <div id="q&a" className="text-white pt-14">
      <div>
        <h1 className="text-[56px] font-semibold flex justify-center leading-[50px] mx-2">
          Q&A
        </h1>
        {/* <p className="text-[18px] text-[#E5E6ED] flex justify-center mt-4 mx-2">
          Join the best backtesting software now to see how intuitive
        </p> */}
        {/* <p className="text-[18px] text-[#E5E6ED] flex justify-center mx-2">
          backtesting can be.
        </p> */}
      </div>

      {/* Accordion Section */}
      <div className="max-w-[1000px] mx-auto my-8 p-4">
        {faqs.map((faq, index) => (
          <CustomAccordion key={index}>
            <CustomAccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Typography>{faq.question}</Typography>
            </CustomAccordionSummary>
            <AccordionDetails>
              <Typography style={{ whiteSpace: "pre-line" }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </CustomAccordion>
        ))}
      </div>

      {/* <div>
        <p className="text-[18px] text-[#E5E6ED] flex justify-center">
          Accepted Payment Methods
        </p>
        <div className="flex justify-center">
          <img src={PaymentPic} alt="PaymentPic" className="w-1/4" />
        </div>
      </div> */}
    </div>
  );
};

export default Started;
