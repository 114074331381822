import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import navbarimg from "../../images/Weeklyfullport.svg";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="flex justify-between h-20 items-center w-4/5">
        <div>
          <img src={navbarimg} alt="DayTrading" />
        </div>
        <div className="nav text-white w-2/5">
          <ul className="flex justify-evenly">
            <li className="relative group">
              <a
                href="#features"
                className="hover:text-gray-300 transition-colors duration-300"
              >
                Features
              </a>
              <span className="absolute left-0 bottom-0 w-full h-0.5 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
            </li>
            <li className="relative group">
              <a
                href="#q&a"
                className="hover:text-gray-300 transition-colors duration-300"
              >
                Common Questions
              </a>
              <span className="absolute left-0 bottom-0 w-full h-0.5 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
            </li>
            <li className="relative group">
              <span className="hover:text-gray-300 transition-colors duration-300">
                About
              </span>
              <span className="absolute left-0 bottom-0 w-full h-0.5 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
            </li>
            <li className="relative group">
              <a
                href="#helpcenter"
                className="hover:text-gray-300 transition-colors duration-300"
              >
                Help Center
              </a>
              <span className="absolute left-0 bottom-0 w-full h-0.5 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
            </li>
          </ul>
        </div>
        <div className="nav text-white">
          <Link
            to={"https://app.chartchamps.com/"}
            className="border-2 py-2 px-5 rounded-full mr-2 drop-shadow-2xl  transition-colors duration-300 ease-in-out hover:bg-white hover:text-black hover:bg-shadow-xl"
          >
            Sign In
          </Link>
        </div>
        <div className="navtoggle ">
          <GiHamburgerMenu size={"25px"} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
