import React from 'react'
import footerpic from '@/../../src/images/NavbarMainImg.svg'
import Socialpic from '@/../../src/images/Socialmedia.png'
import navbarimg from "../../images/Weeklyfullport.svg";

const Footer = () => {
  return (
    <>
    <div className=' flex justify-center'>
    <div className='text-white flex justify-between w-[80%] flex-wrap border-b-2 border-[#34384C] py-8 '>
        <div>
            <div>
            <img src={navbarimg} alt="footerpic" />
            </div>
            <p className='text-[16px] my-6'>Your best DayTrading partner.</p>
            <div>
                <img src={Socialpic} alt="Socialpic" />
            </div>

        </div>
        <div className='my-2'>
            <h6 className='text-[20px] font-semibold mb-3' >Quick Links.</h6>
            <p className='text-[#A5ADCF] text-[16px]'>Quick Links.</p>
            <p className='text-[#A5ADCF] text-[16px] my-4'>Pricing</p>
            <p className='text-[#A5ADCF] text-[16px]'>About</p>
        </div>
        <div>
         <h6 className='text-[20px] font-semibold mb-3 '>Submit for updates.</h6>
            <p className='text-[#A5ADCF] text-[16px] mb-4 w-[65%] flex-wrap'>Subscribe to get update and notify our exchange and products.</p>
            <div className='bg-[#2F324180] rounded-full py-2 px-2 w-[65%] flex justify-between'>
                <input type="text" placeholder='Enter your email address' className='py-2 px-2 bg-transparent rounded-full'  />
                <button className='bg-[#5235E8] py-2 px-4 rounded-full'>Send</button>
            </div>
        </div>
    </div>
    </div>
    <div className='flex justify-center py-6'>
    <div className='flex justify-between flex-wrap w-[80%] '>
        <div>
            <p className='text-[#5D6588] text-[14px]'>Cryptolly ©. All rights reserved.</p>
        </div>
        <div className='flex'>
        <p className='text-[#5D6588] text-[14px] border-r-2 border-[#34384C] mr-2 pr-2'>Term of Service</p>
        <p className='text-[#5D6588] text-[14px]'>Privacy Policy</p>

        </div>
    </div>
    </div>
      
    </>
  )
}

export default Footer
